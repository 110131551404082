import React from 'react';

interface IDotNavigationProps {
    scrollState: number;
    activeSlide: number;
    onSlideSelect: (slideNo: number) => void;
    content: any;
}

interface IDotNavigationState {
}

class DotNavigation extends React.Component<IDotNavigationProps, IDotNavigationState> {

    render() {
        const dots: any[] = [];

        for (let i = 0 ; i < this.props.content.length ; i++) {
            const label = this.props.content[i].label;

            dots.push((
                <div key={i} className={`dot-item ${i === this.props.activeSlide ? 'active' : ''} ${i < this.props.activeSlide ? 'visible' : ''}`}>
                    <div className="dot-label" onClick={() => this.props.onSlideSelect(i)}>
                        <span>{ label }</span>
                    </div>
                    <div className="line-container">
                        <div className="line" />
                    </div>
                    <div className="dot-step" onClick={() => this.props.onSlideSelect(i)}>
                        <span>0</span>
                        <span className="dot-index">{i + 1}</span>
                    </div>
                </div>
            ))
        }

        return (
            <div className="dot-navigation">
                { dots }
            </div>
        );
    }
}

export default DotNavigation;
