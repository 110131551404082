export const MOBILE_WIDTH = 992;
export const CONTACT_ENDPOINT = 'https://api.solveestudio.com/contact';
export const CONTACT_PSK_KEY = 'XH5nNePqjv2gQzIt182SnlD2gAnoaWY17oAq'

export enum slideType {
    Contact = 'contact',
    Portfolio = 'portfolio',
}
export enum viewContentBtnType {
    Contact = 'contact',
    More = 'learnMore',
}

export enum sidePanelContentSectionType {
    Paragraph = 'paragraph',
    List = 'list',
}

export type IContent = ISlide[];

export type ISlide = ISlideContact | ISlidePortfolio;

export type ISlideContact = {
    name: string,
    type: slideType,
    label: string,
    viewContent: IViewContent,
}

export type ISlidePortfolio = {
    name: string,
    type: slideType,
    label: string,
    viewContent: IViewContent,
    sidePanelContent: ISidePanelContent,
}

export type IViewContent = {
    h1?: string[],
    h2?: string[],
    p?: string[],
    btn?: IViewContentBtn,
}
export type IViewContentBtn = {
    type: viewContentBtnType,
}

export type ISidePanelContent = {
    title: string,
    sections?: ISidePanelContentSection[],
}

export type ISidePanelContentSection = {
    type: sidePanelContentSectionType,
    content: ISidePanelContentSectionContent,
}

export type ISidePanelContentSectionContent =
    ISidePanelContentSectionContentParagraph | ISidePanelContentSectionContentList;

export type  ISidePanelContentSectionContentParagraph = {
    h2: string,
    p: string[],
}

export type  ISidePanelContentSectionContentList = {
    h2: string,
    ul: string[],
}