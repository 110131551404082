const en = {
    menu: {
        contact: 'contact us',
    },
    maintenance: {
        pageNotFound: 'Błąd - nie znaleziono strony'
    },
    button: {
        learnMore: 'Learn more',
        contact: 'contact us',
        accept: 'Accept',
        decline: 'Decline',
    },
    form: {
        name: 'Name',
        email: 'Email',
        message: 'Message',
        send: 'Send',
        dataProcessing: `We use provided information in order to contact you regarding your question. You can read our <a href="https://dev-solvee.ovh/ss/wp-content/uploads/2024/03/Solvee-Studio_Privacy-Policy.pdf" target="_blank">Privacy Policy</a>.`,
        recaptchaDetails: 'This site is protected by reCAPTCHA and the Google <0>Privacy Policy</0> and <1>Terms of Service</1> apply.',
    },
    cookies: {
        title: 'Cookies policy',
        description: 'Our website uses cookies to alalyze how that site is used and to ensure your experience is consistent between the visits.',
        link: 'Cookies Policy',
    }
};

export default en;
