import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {LanguageLocale} from "../../constants/locales";

export interface IContactFormValues {
    name?: string | null;
    email?: string | null;
    message?: string | null;
    agreeToPrivacyPolicy?: boolean;
}

export interface ISagaState {
    activeLanguage: LanguageLocale;
    isCookieAccepted: boolean | null;
    contactFormValues: IContactFormValues | null;
}

export interface IChangeLanguage {
    activeLanguage: LanguageLocale;
}

export interface IChangeCookies {
    isCookieAccepted: boolean;
}

export interface IChangeContactFormValues {
    values: IContactFormValues | null;
}

const initialState: ISagaState = {
    activeLanguage: LanguageLocale.EN,
    isCookieAccepted: null,
    contactFormValues: null,
};

const sagaSlice = createSlice({
    name: "saga",
    initialState: initialState,
    reducers: {
        changeActiveLanguage: {
            reducer: (state: ISagaState, action: PayloadAction<IChangeLanguage>) => {
                return {
                    ...state,
                    activeLanguage: action.payload.activeLanguage,
                }
            },
            prepare(activeLanguage: LanguageLocale) {
                return {
                    payload: {activeLanguage: activeLanguage}
                };
            }
        },
        changeCookies: {
            reducer: (state: ISagaState, action: PayloadAction<IChangeCookies>) => {
                return {
                    ...state,
                    isCookieAccepted: action.payload.isCookieAccepted,
                }
            },
            prepare(isCookieAccepted: boolean) {
                return {
                    payload: {isCookieAccepted: isCookieAccepted}
                };
            }
        },
        changeContactFormValues: {
            reducer: (state: ISagaState, action: PayloadAction<IChangeContactFormValues>) => {
                return {
                    ...state,
                    contactFormValues: action.payload.values,
                }
            },
            prepare(values: IContactFormValues | null) {
                return {
                    payload: {values: values}
                };
            }
        },
    }
});

export const {
    changeActiveLanguage,
    changeCookies,
    changeContactFormValues,
} = sagaSlice.actions;

export default sagaSlice.reducer;
