const en = {
    menu: {
        contact: 'contact us',
    },
    maintenance: {
        pageNotFound: 'Błąd - nie znaleziono strony'
    },
    button: {
        learnMore: 'Learn more',
        contact: 'contact us'
    },
    form: {
        name: 'Name',
        email: 'Email',
        message: 'Message',
        send: 'Send',
        dataProcessing: `Wykorzystujemy przekazane nam informacje, aby kontaktować się z Tobą w sprawie twojego zapytania. Przeczytaj naszą <a href="#" target="_blank">politykę prywatności</a>.`,
    }
};

export default en;
