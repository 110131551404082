import React, {RefObject} from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import DotNavigation from '../DotNaviagation';
import BackgroundShape from '../BackgroundShape';
import SidePanel from '../SidePanel';
import ContentHost from '../ContentHost';
import BtnNavigation from '../BtnNaviagation';
import {enContent} from './en';
import {IContent, MOBILE_WIDTH, slideType} from './types';
// import CookieNotice from "../CookieNotice";
import {connect} from "react-redux";
import { changeCookies } from '../../store/reducers/sagaSlice';
import {RootState} from "../../store/reducers";
import {isCookieAcceptedSelector} from "../../store/selectors/sagaSelectors";

interface IPageHostProps extends WithTranslation {
    readonly isCookieAccepted: boolean | null;
    readonly changeCookies: typeof changeCookies;
}

interface IPageHostState {
    activeSlide: number;
    scrollState: number;
    sidePanel: slideType | null;
    content: IContent;
}

export const SLIDE_INTERVAL: number = 1;

class PageHost extends React.Component<IPageHostProps, IPageHostState> {

    private slideRef: RefObject<any>;

    state = {
        activeSlide: 0,
        scrollState: 0,
        sidePanel: null,
        content: enContent,
    };

    constructor(props: IPageHostProps) {
        super(props);

        this.slideRef = React.createRef();
    }

    slideNumber = () => this.state.content.length;

    componentDidMount() {
        document.addEventListener("wheel", this.handleScroll);
        document.addEventListener("keydown", this.handleKeyDown);
    }

    componentWillUnmount() {
        window.removeEventListener('wheel', this.handleScroll);
        window.removeEventListener('keydown', this.handleKeyDown);
    }

    private handleScroll = (e: any) => {
        if (window.innerWidth <= MOBILE_WIDTH) return;
        this.handleView(e.wheelDeltaY < 0 ? 1 : -1);
    };

    private handleTouch = (e: any) => {
        console.log(e);
    };

    private handleKeyDown = (e: any) => {
        if (window.innerWidth <= MOBILE_WIDTH) return;

        let delta: number | null = null;
        switch (e.keyCode) {
            case 37:
                delta = -1;
                break;
            case 38:
                delta = -SLIDE_INTERVAL;
                break;
            case 39:
                delta = 1;
                break;
            case 40:
            case 32:
                delta = SLIDE_INTERVAL;
                break;
        }

        if (delta) this.handleView(delta);
    };

    private handleView(delta: number): void {
        if (this.state.sidePanel) {
            return;
        }

        const maxScrollState: number = SLIDE_INTERVAL * this.slideNumber() - 1;

        if (this.state.scrollState <= 0 && delta < 0 ) return;
        if (this.state.scrollState >= maxScrollState  && delta > 0 ) return;

        let scrollState: number = this.state.scrollState + delta;
        if (scrollState < 0) scrollState = 0;
        if (scrollState > maxScrollState) scrollState = maxScrollState;

        this.setState({
            activeSlide: Math.floor(scrollState / SLIDE_INTERVAL),
            scrollState: scrollState,
        });
    }

    render() {
        const backgroundImg = require(`./../../assets/images/slide-${this.state.activeSlide + 1}.webp`),
            imageClassName = this.state.sidePanel ? ` image-slideOut` : '';

        return (
            <main className="view">
                <div className="slide" ref={this.slideRef}>
                    <div className="main-content-wrapper">

                        <div className="logo" onClick={() => this.selectSlide(0)}/>
                        <div className="menu mobile">
                            <div className="menu-item" onClick={() => this.toggleSidePanel(slideType.Contact)}>
                                contact us
                            </div>
                        </div>

                        <ContentHost slidesNumber={this.slideNumber()}
                                     content={this.state.content}
                                     activeSlide={this.state.activeSlide}
                                     onOpenSidePanel={() => this.toggleSidePanel(this.state.content[this.state.activeSlide].type)} />

                        <div key={this.state.activeSlide} className={`image-container${imageClassName}`}>
                            <div className="image"
                                 style={backgroundImg ? {backgroundImage: `url(${backgroundImg})`} : undefined} >

                                 <img src={backgroundImg} alt='Project image'/>
                            </div>
                        </div>

                        <BtnNavigation slideNumber={this.slideNumber()}
                                       activeSlide={this.state.activeSlide}
                                       onSlideChange={this.selectSlide} />
                    </div>

                    <SidePanel activeSlide={this.state.activeSlide}
                               slide={this.state.content[this.state.activeSlide]}
                               sidePanel={this.state.sidePanel}
                               onToggleSidePanel={this.toggleSidePanel} />
                </div>

                <DotNavigation activeSlide={this.state.activeSlide}
                               content={this.state.content}
                               scrollState={this.state.scrollState}
                               onSlideSelect={this.selectSlide} />

                <BackgroundShape activeSlide={this.state.activeSlide}/>

                {/*{null === this.props.isCookieAccepted ? <CookieNotice changeCookies={this.props.changeCookies}/> : null}*/}
            </main>
        );
    }

    private selectSlide = (activeSlide: number) => {
        if (activeSlide < 0 ) return;
        if (activeSlide > this.slideNumber() - 1 ) return;

        this.setState({
            activeSlide: activeSlide,
            scrollState: activeSlide * SLIDE_INTERVAL,
        });

        for (let i = this.slideRef.current.scrollTop; i > 0; i--) {

            setTimeout(() => this.slideRef.current.scrollTo(i, 0), 50);
        }

    };

    private toggleSidePanel = (sidePanel: slideType | null) => {
        this.setState({sidePanel: sidePanel});
    }
}

export default connect(
    (state: RootState) => ({
        isCookieAccepted: isCookieAcceptedSelector(state)
    }),
    {
        changeCookies
    }
)(withTranslation()(PageHost));
