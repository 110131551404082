import React from 'react';
import {ISlide, ISlidePortfolio, MOBILE_WIDTH, slideType} from '../PageHost/types';
import SidePanelContactForm from './SidePanelContactForm';
import SidePanelDetails from './SidePanelDetails';
import Translation from '../Translation';
import {withTranslation, WithTranslation} from "react-i18next";
import {isMobile} from 'react-device-detect';

interface ISidePanelProps extends WithTranslation{
    activeSlide: number;
    slide: ISlide;
    sidePanel: slideType | null;
    onToggleSidePanel: (e: slideType | null) => void;
}

class SidePanel extends React.Component<ISidePanelProps> {
    private sidePanelContentRef: any;
    constructor(props: ISidePanelProps) {
        super(props);

        this.sidePanelContentRef = React.createRef();
    }

    componentDidMount(): void {
        document.addEventListener('mousedown', this.handleOutsideClick);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleOutsideClick);
    }

    render() {
        return (
            <>
                <div className={`side-panel-wrapper ${this.props.sidePanel ? 'open' : 'close' }`}>
                    <div className="side-panel">
                        <div className='menu'>
                            <div className="menu-item"
                                 onClick={() => this.props.onToggleSidePanel(slideType.Contact)}>
                                <Translation text={'menu.contact'} />
                            </div>
                        </div>

                        <div className={`side-panel-content-wrapper ${!isMobile ? "desktop" : ''}`} ref={this.sidePanelContentRef}>
                            { this.renderSidePanelHeader() }
                            <div className="side-panel-content">
                                <div className={!isMobile ? "side-panel-details" : ''}>
                                    { this.renderSidePanelContent() }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="side-panel-overlay"/>
                </div>

            </>
        );
    }

    private renderSidePanelHeader = () => {
        const {t} = this.props,
            title = this.props.sidePanel === slideType.Portfolio ?
            (this.props.slide as ISlidePortfolio).sidePanelContent.title : t('menu.contact');

        return (
            <div className="side-panel-header-container">
                <div className="side-panel-header">
                    <h2 className="title">{ title }</h2>
                    <button className="btn-close" onClick={() => this.props.onToggleSidePanel(null)}/>
                </div>
            </div>
        );
    };

    private renderSidePanelContent = () => {
        switch(this.props.sidePanel) {
            case slideType.Portfolio:
                return <SidePanelDetails slide={(this.props.slide as ISlidePortfolio)} />;
            default:
                return <SidePanelContactForm />;
        }
    };

    private handleOutsideClick = (event: any) => {
        if (window.innerWidth <= MOBILE_WIDTH) return;
        event.stopPropagation();
        if (this.sidePanelContentRef && this.sidePanelContentRef.current &&
            !this.sidePanelContentRef.current.contains(event.target) && this.props.sidePanel) {
            this.props.onToggleSidePanel(null)
        }
    };
}

export default withTranslation()(SidePanel);
