import React from 'react';
import {
    ISidePanelContentSection, ISidePanelContentSectionContentList,
    ISidePanelContentSectionContentParagraph,
    ISlidePortfolio,
    sidePanelContentSectionType,
} from '../../PageHost/types';


interface ISidePanelDetailsProps  {
    slide: ISlidePortfolio;
}

interface ISidePanelDetailsState {
}

class SidePanelDetails extends React.Component<ISidePanelDetailsProps, ISidePanelDetailsState> {

    render() {
        if (!this.props.slide.sidePanelContent.sections) return;

        const sections: any[] = [];
        this.props.slide.sidePanelContent.sections.forEach((section:any, i:number) =>
            sections.push(this.renderSection(section, i)))

        return sections
    }

    private renderSection(section: ISidePanelContentSection, i:number) {
        if (section.type === sidePanelContentSectionType.Paragraph) {
            const paragraphs: any[] = [];
            (section.content as ISidePanelContentSectionContentParagraph).p.forEach((item:any, i:number) =>
                paragraphs.push(<p key={i}>{ item }</p>));
            return (
                <div key={i}>
                    <h2>{ section.content.h2 }</h2>
                    { paragraphs }
                </div>
            )
        }
        if (section.type === sidePanelContentSectionType.List) {
            const items: any[] = [];
            (section.content as ISidePanelContentSectionContentList).ul.forEach((item:any, i:number) =>
                items.push(<li key={i}>{ item }</li>));
            return (
                <div key={i}>
                    <h2>{ section.content.h2 }</h2>
                    <ul>{ items }</ul>
                </div>
            )
        }
        return null;
    }

}

export default SidePanelDetails;
