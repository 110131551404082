import React from 'react';
import './App.scss';
import PageHost from "./components/PageHost";


function App() {
    return <PageHost/>;
}

export default App;
