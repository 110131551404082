import {IContent, sidePanelContentSectionType, slideType, viewContentBtnType} from './types';

export const enContent: IContent = [
    {
        name: "home",
        type: slideType.Contact,
        label: "",
        viewContent: {
            h1: [
                'Welcome to Solvee Studio.',
            ],
            h2: [
                'Consultation for manifesting your products and ideas with style and efficiency.',
            ],
            p: [
                'At Solvee Studio we achieve our clients\' goals by combining years of expertise, creativity, strategic planning and flexibility, wrapped inside a business driven process of development.',
                'We focus on custom systems where deep understanding of business processes are required, eventually becoming a member of your team which you can always rely on.'
            ],
            btn: {
                type: viewContentBtnType.Contact,
            }
        },
    },
    {
        name: "aboutUs",
        type: slideType.Contact,
        label: "About us",
        viewContent: {
            h1: [
                'About us'
            ],
            p: [
                'We are a team of young, passionate developers based in Warsaw, Poland. Our technologies stack ranges from PHP (Symfony, Sylius), Java (Kotlin, Spring Boot) and NodeJS on the backend to Angular, ReactJS and React Native on the frontend.',
                'We work with you for IT consultation and analysis, creating project specification, mocks and graphic layouts, development of complex multiapplicational systems both for web and mobile, and further support. ',
                'Let us know how we can help develop your project.'
            ],
            btn: {
                type: viewContentBtnType.Contact,
            }
        },
    },
    {
        name: "logistics",
        type: slideType.Portfolio,
        label: "Maps & Real Time Logistics",
        viewContent: {
            h1: [
                "Maps & Real Time Logistics"
            ],
            p: [
                'We have been involved in this project since 2016. During that time we have developed solutions for managing couriers\' fleet in a instant delivery model. It covers everything from the whole delivery process: from placing an order (API integration with third parties), managing orders within dispatcher panel, up to its acceptance in the courier\'s mobile app, tracking on a map and eventually placing a signature by the receiver.'
            ],
            btn: {
                type: viewContentBtnType.More,
            }
        },
        sidePanelContent: {
            title: "Maps & Real Time Logistics",
            sections: [
                {                   type: sidePanelContentSectionType.Paragraph,
                    content: {
                        h2: "Logistics on demand",
                        p: [
                            'Advanced courier fleet management system made with instant delivery model in mind.  It allows for process management in the company, logistics management and offers customers an innovative way of ordering and tracking shipments. A courier company has been operating using our system since 2016.',
                            'MVP of the system developed in waterfall approach, then we switched to agile to allow for constant changes and improvements required to the flow in the application. Overall result was that the system was delivered to the client as soon as it was possible, but the client was able to add adjustments with a great amount of flexibility when end customers\' needs were better recognised.'
                        ]
                    }
                },
                {
                    type: sidePanelContentSectionType.List,
                    content: {
                        h2: "Features",
                        ul: [
                            'Automatic commissioning for transports and one-off transports',
                            'Real-time shipment management and tracking',
                            'Integration API, PayU',
                            'User-friendly customer interface',
                            'Real time information exchange with couriers via our mobile application',
                            'Automatic notification for customers about status changes via configurable channels like e-mail or SMS',
                        ]
                    }
                },
                {
                    type: sidePanelContentSectionType.List,
                    content: {
                        h2: "Technologies",
                        ul: [
                            'Backend: Java / Kotlin / MongoDB',
                            'Frontend: Ionic / JavaScript / Angular',
                            'SMS integration',
                            'API for mass order placing',
                            'PAYU payment system',
                            'Live tracking map for customers',
                            'Customer Signature within the mobile app'
                        ]
                    }
                }
            ]
        }
    },
    {
        name: "videocalls",
        type: slideType.Portfolio,
        label: "Chat, Calendars, Viedocalls and Stripe Engine",
        viewContent: {
            h1: [
                "Chat, Calendars, Viedocalls and Stripe Engine"
            ],
            p: [
                'This multi applicational SaaS system supports its users in various ways. It allows scheduling doctors’ online appointments, which can be then bought online by patients. Video consultations can be anonymous or for logged users only and calendars can be embedded as widgets. All supported by Stripe engine with split payment methods.'
            ],
            btn: {
                type: viewContentBtnType.More,
            }
        },
        sidePanelContent: {
            title: "Platform for healthcare",
            sections: [
                {
                    type: sidePanelContentSectionType.Paragraph,
                    content: {
                        h2: "Video consultation for clinics",
                        p: [
                            'It consists of several web applications improving clinics\' performance as well as mobile app supporting Patients.',
                            'Project was under development when the pandemic bursted. We instantly reacted for our client\'s changed needs, adjusting goals to the new reality.'
                        ]
                    }
                },
                {
                    type: sidePanelContentSectionType.List,
                    content: {
                        h2: "Features",
                        ul: [
                            'Managing clinic\'s profiles',
                            'Creating doctor\'s calendar of video appointment to buy',
                            'Support for multiple languages and time zones',
                            'Selling medical travel insurances',
                            'Patient aftercare process',
                            'Chat with history and attachments',
                            'Embedding clinic\s widgets',
                            'Clinic\'s Stripe KYC and AML onboarding'
                        ]
                    }
                },
                {
                    type: sidePanelContentSectionType.List,
                    content: {
                        h2: "Technologies",
                        ul: [
                            'Backend: PHP / Symfony / PostgreSQL',
                            'Frontend: ReactJS / React Native',
                            'Stripe payment engine - split-payment and onboarding',
                            'WebRTC / Agora.io integration / Jitsi standalone server',
                        ]
                    }
                }
            ]
        }
    },
    {
        name: "eCommerce",
        type: slideType.Portfolio,
        label: "Customized eCommerce",
        viewContent: {
            h1: [
                "Customized eCommerce"
            ],
            p: [
                'We use Sylius platform as a base for our eCommerce projects. It allows us to be more flexible and adjustable to our clients\' vision. We focus on custom concepts usually requiring integrations with other systems or merging data from many different sources into one customer panel. Different payment and shipping methods, custom checkout and email flow. As you wish.'
            ],
            btn: {
                type: viewContentBtnType.More,
            }
        },
        sidePanelContent: {
            title: "Customized eCommerce",
            sections: [
                {
                    type: sidePanelContentSectionType.Paragraph,
                    content: {
                        h2: "Customized eCommerce",
                        p: [
                            'Our eCommerce Projects are characterized by deep analysis of clients need. We adjust all process to his its specific needs bringing out the unique value of the Project.'
                        ]
                    }
                },
                {
                    type: sidePanelContentSectionType.List,
                    content: {
                        h2: "Features",
                        ul: [
                            'Custom integration with InPost and DPD',
                            'Product packages, variants and options',
                            'Custom Checkout flow'
                        ]
                    }
                },
                {
                    type: sidePanelContentSectionType.List,
                    content: {
                        h2: "Technologies",
                        ul: [
                            'Backend: PHP / Symfony / Sylius / MySQL',
                            'Frontend: twig / ReactJS',
                            'Custom integrations with third party API',
                            'Data migration from older Systems',
                            'RESTful API eCommerce api for mobile apps'
                        ]
                    }
                }
            ]
        }
    },
    {
        name: "hardware",
        type: slideType.Portfolio,
        label: "In Cooperation with Hardware",
        viewContent: {
            h1: [
                "In Cooperation with Hardware"
            ],
            p: [
                'In this project we focused on developing a complex system for electric yacht management. We were responsible for applications both for frontend users (such as tablet yacht panel, skipper and boatsman mobile app and botsman\'s web panel), as well as backend side applications (local server application and global server application).'
            ],
            btn: {
                type: viewContentBtnType.More,
            }
        },
        sidePanelContent: {
            title: "In Cooperation with Hardware",
            sections: [
                {
                    type: sidePanelContentSectionType.Paragraph,
                    content: {
                        h2: "Electric Yacht Management System",
                        p: [
                            'That was an interesting Startup project, where we cooperated with Finish company responsible for delivering yachts\' hardware, which we integrate to [XXX] via CAN bus in the data collection model from each yacht to the cloud in real time.'
                        ]
                    }
                },
                {
                    type: sidePanelContentSectionType.List,
                    content: {
                        h2: "Features",
                        ul: [
                            'Managing yachts\' fleet ',
                            'Managing yachts\' reservations',
                            'Registering new yacht with a QR Code',
                            'Collecting yacht\'s data such us battery state, position, speed, RPM, etc.',
                            'Weather and draft alerts',
                            'Live position tracking',
                            'Real time synchronization between  devices on yachts and the "cloud" server',
                            'Algorithms for collision detection with obstacles and shallows. Alert notification.'
                        ]
                    }
                },
                {
                    type: sidePanelContentSectionType.List,
                    content: {
                        h2: "Technologies",
                        ul: [
                            'Backend: Java / Spring Boot / MongoDB',
                            'Frontend: ReactJS / React Native',
                            'Asynchronous data transfer',
                            'Local yacht server',
                            'yacht data rail',
                        ]
                    }
                }
            ]
        }
    },
    {
        name: "Contact",
        type: slideType.Contact,
        label: "Contact us",
        viewContent: {
            h1: [
                "Contact us"
            ],
            h2: [
                '',
                'contact@solvee.pl',
            ],
            p: [
                'Solvee Studio Sp. z o.o.',
                'Zuga 29, 01-806 Warsaw',
                'NIP: 1182173591'
            ],
            btn: {
                type: viewContentBtnType.Contact,
            }
        },
    },
];
