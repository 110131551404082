import React, {Component} from 'react';
import 'animate.css/animate.css';
import styles from './styles.module.scss';


export enum RouterTransition {
    FADE_IN = 'fadeIn',
    FADE_IN_LEFT = 'fadeInLeft',
    ZOOM_IN = 'zoomIn',
    FADE_OUT_RIGHT = 'fadeOutRight',
    FADE_IN_RIGHT = 'fadeInRight',
    NONE = 'none'
}

interface IConnectedContentWrapperProps {}

interface IExternalContentWrapperProps {
    children: any;
    routerTransition?: RouterTransition;
    customStyles?: string;
    animationDelay?: string;
}

interface IContentWrapperState {}

interface IContentWrapperProps extends IConnectedContentWrapperProps, IExternalContentWrapperProps {}

class ContentWrapper extends Component<IContentWrapperProps, IContentWrapperState> {

    render() {
        return (
            <div className={`${this.props.routerTransition ? `animate__animated animate__${this.props.routerTransition}` : 
                            `animate__animated animate__${RouterTransition.FADE_IN}`}
                            ${this.props.animationDelay ? this.props.animationDelay : ''}
                            ${this.props.customStyles ? this.props.customStyles : ''} 
                            ${styles.animatedContent}`}>
                    {this.props.children}
            </div>
        );
    }
}

export default ContentWrapper;
