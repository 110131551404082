import React from 'react';
import ContactForm from './ContactForm';

interface ISidePanelContactFormProps {
}

interface ISidePanelContactFormState {
    isSent: boolean;
}

class SidePanelContactForm extends React.Component<ISidePanelContactFormProps, ISidePanelContactFormState> {

    state = {
        isSent: false
    };

    render() {
        if (this.state.isSent) {
            return (
                <div className="success-info">
                    <h2>
                        Right on!
                    </h2>
                    <p>
                        You message has been sent. We will get back to you soon.
                    </p>
                    <div className="btn-form-container">
                        <button className="btn-form" onClick={() => this.setState({isSent: false})}>again</button>
                    </div>
                </div>
            )
        }

        return (<ContactForm onSent={() => this.setState({isSent: true})}/>)
    }
}

export default SidePanelContactForm;
