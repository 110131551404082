import React from 'react';
import ContentWrapper from "../ContentWrapper";
import Translation from "../Translation";
import {IContent, ISlide, slideType} from '../PageHost/types';


interface IContentHostProps {
    activeSlide: number;
    slidesNumber: number;
    onOpenSidePanel: () => void;
    content: IContent;
}

interface IContentHostState {
}

class ContentHost extends React.Component<IContentHostProps, IContentHostState> {

    render() {
        const slide: ISlide = this.props.content[this.props.activeSlide];

        return (
            <ContentWrapper>
                <div className="content-container" key={this.props.activeSlide}>
                    <div className="content">
                        { this.renderTitle(slide.viewContent.h1) }
                        { this.renderSubTitle(slide.viewContent.h2) }
                        { this.renderSlideIndicator(slide.type) }
                        { this.renderParagraphs(slide.viewContent.p) }
                        { this.renderButton(slide.viewContent.btn) }
                    </div>
                    <div className="slide-number">
                        <p>0{ this.props.activeSlide + 1 }</p>
                    </div>
                </div>
            </ContentWrapper>
        );
    }

    private renderTitle(h1: string[] = []) {
        return h1.map((item:string, i:number) => <h1 key={i} className="content-title">{ item }</h1>)
    }

    private renderSubTitle(h2: string[] = []) {
        return  h2.map((item:string, i:number) => <h2 key={i} className="content-subtitle">{ item }</h2>);
    }

    private renderSlideIndicator(type: slideType) {
        if (type === slideType.Contact) return;

        return (
            <div className="slide-indicator">
                <p>
                    0{ this.props.activeSlide + 1 } <span className="slide-divider" /> 0{this.props.slidesNumber}
                </p>
            </div>
        );
    }

    private renderParagraphs(p: string[] = []) {
        return (
            <div className="content-description">
                {p.map((item: string, i: number) => <p key={i} >{ item }</p>)}
            </div>
        )
    }

    private renderButton(button: any) {
        if (!button) return null;

        return (
            <div className="btn-more-container">
                <button className="btn-more" onClick={this.props.onOpenSidePanel}>
                    <Translation text={`button.${button.type}`} />
                </button>
            </div>
        )
    }
}

export default ContentHost;
