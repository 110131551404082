import * as React from "react";
import {useTranslation} from "react-i18next";


interface ITranslationProps {
    readonly text: string;
    readonly config?: { [key: string]: any };
}

const Translation = (props: ITranslationProps) => {
    const {t} = useTranslation();

    return <React.Fragment>{t(props.text, props.config)}</React.Fragment>;
};

export default Translation;
