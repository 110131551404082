import React, {RefObject} from 'react';
import axios from "axios";
import FormControl, {formControlType} from './FormControl';
import Translation from '../../../Translation';
import {CONTACT_ENDPOINT, CONTACT_PSK_KEY} from '../../../PageHost/types';
import ReCAPTCHA from "react-google-recaptcha";
import {Trans} from "react-i18next";
import {connect} from "react-redux";
import {RootState} from "../../../../store/reducers";
import {isContactFormValuesSelector} from "../../../../store/selectors/sagaSelectors";
import {changeContactFormValues, IContactFormValues} from '../../../../store/reducers/sagaSlice';
import {BehaviorSubject, Subscription} from 'rxjs';
import {debounceTime, filter, tap} from 'rxjs/operators';


export enum FormControlChangeType {
    Init = 'init',
    Internal = 'internal',
    User = 'user',
}

interface IConnectedContactFormProps {
    readonly formValues: IContactFormValues | null;
    readonly changeContactFormValues: typeof changeContactFormValues;
}

interface IContactFormProps extends IConnectedContactFormProps {
    onSent: (isSent: boolean) => void;
}

interface IContactFormState {
    name: string | null;
    email: string | null;
    message: string | null;
    agreeToPrivacyPolicy: boolean;
    isRecaptchaValid: boolean;
    isProcessing: boolean;
}

class ContactForm extends React.Component<IContactFormProps, IContactFormState> {
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    private reCaptchaRef: RefObject<any>;
    private subscriptions: Subscription[] = [];

    state = {
        name: null,
        email: null,
        message: null,
        agreeToPrivacyPolicy: false,
        isRecaptchaValid: false,
        isProcessing: false,
    };

    constructor(props: IContactFormProps) {
        super(props);
        this.reCaptchaRef = React.createRef();
    }

    get isValid(): boolean  {
        return !!this.state.name && !!this.state.email && !!this.state.message && this.state.agreeToPrivacyPolicy;
    }

    componentDidMount() {
        if (this.props.formValues) {
            this.setState({
                name: this.props.formValues.name ? this.props.formValues.name : null,
                email: this.props.formValues.email ? this.props.formValues.email : null,
                message: this.props.formValues.message ? this.props.formValues.message : null,
                agreeToPrivacyPolicy: this.props.formValues.agreeToPrivacyPolicy ? this.props.formValues.agreeToPrivacyPolicy : false
            })
        }

        this.subscriptions.push(
            this.onValueStateChange$
                .pipe(
                    filter((data: any) => data && data.changeType === FormControlChangeType.User),
                    debounceTime(500),
                    tap((data: any) => this.changeFormValues(data))
                )
                .subscribe()
        );
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        const siteKey = process.env.REACT_APP_GOOGLE_SITE_KEY;
        return (
            <div className={`form-wrapper ${this.state.isProcessing ? ' isProcessing' : ''}`}>
                <form className="form">
                    <h2>
                        Got a question?
                    </h2>
                    <p>
                        We are here for you.
                    </p>
                    <FormControl type={formControlType.inputText}
                                 label={'name'}
                                 onChange={(value: string | null) => {
                                     this.onValueStateChange('name', value);
                                     this.setState({name: value});
                                 }} />
                    <FormControl type={formControlType.inputEmail}
                                 label={'email'}
                                 onChange={(value: string | null) => {
                                     this.onValueStateChange('email', value);
                                     this.setState({email: value});
                                 }} />
                    <FormControl type={formControlType.textarea}
                                 label={'message'}
                                 onChange={(value: string | null) => {
                                     this.onValueStateChange('message', value);
                                     this.setState({message: value});
                                 }} />

                    <FormControl type={formControlType.checkbox}
                                 label={'dataProcessing'}
                                 isLabelRaw={true}
                                 onChange={(value: boolean) => {
                                     this.onValueStateChange('agreeToPrivacyPolicy', value);
                                     this.setState({agreeToPrivacyPolicy: value});
                                 }} />

                    {siteKey ? <ReCAPTCHA
                        sitekey={siteKey}
                        ref={this.reCaptchaRef}
                        size='invisible'
                    /> : null}

                    <div className='recaptcha-details'>
                        <Trans i18nKey="form.recaptchaDetails"
                               components={[
                                   <a href="https://policies.google.com/privacy"></a>,
                                   <a href="https://policies.google.com/terms"></a>
                               ]} />
                    </div>

                    <div className="btn-form-container">
                        <button className={`btn-form${this.isValid ? ' valid' : ''}`}
                                onClick={(e:any) => this.send(e)}>
                            <span className="btn-form-label"><Translation text={'form.send'} /></span>
                        </button>
                    </div>
                </form>
                <div className="btn-form-loader"><span/></div>
            </div>

        )
    }

    private changeFormValues = (data: {[key: string]: any}) => {
        const formValues: IContactFormValues = {};
        Object.keys(data).forEach((key) => {
            if (key === 'controlName' && data[key] === 'name') {
                formValues.name = data['value'];
            }

            if (key === 'controlName' && data[key] === 'email') {
                formValues.name = data['value'];
            }

            if (key === 'controlName' && data[key] === 'message') {
                formValues.name = data['value'];
            }

            if (key === 'controlName' && data[key] === 'agreeToPrivacyPolicy') {
                formValues.name = data['value'];
            }
        });
        this.props.changeContactFormValues(formValues);
    }

    private onValueStateChange = (controlName: string, value: any, changeType: FormControlChangeType = FormControlChangeType.User) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private send = (e: any) => {
        if ( this.state.isProcessing || !this.isValid) return;
        e.preventDefault();
        // wysłanie forma na serwer kiedy success:
        // this.props.onSent(true);
        this.setState({isProcessing: true});

        const params = new URLSearchParams(),
            name = this.state.name ? this.state.name : '',
            email = this.state.email ? this.state.email : '',
            message = this.state.message ? this.state.message : '';
        params.append('contact[name]', name);
        params.append('contact[email]', email);
        params.append('contact[message]', message);
        params.append('contact[subject]', '');
        params.append('contact[phone]', '');

        this.reCaptchaRef.current.execute()
            .then((token: any) => {
                params.append('contact[captcha]', token);
                axios.post(
                    CONTACT_ENDPOINT,
                    params,
                    {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                            'X-CONTACT-PSK-KEY': CONTACT_PSK_KEY,
                        }
                    }
                ).then((resp:any) => {
                    console.log('success',resp);
                    this.props.changeContactFormValues(null);

                }).catch((err: any) => {
                    console.log('err',err);

                }).finally(() => {
                    this.setState({isProcessing: false});
                    this.props.onSent(false);
                    this.reCaptchaRef.current.reset();
                });
            })
            .catch((err: any) => {
                console.log('err',err);
            })
    }
}

export default connect(
    (state: RootState) => ({
        formValues: isContactFormValuesSelector(state)
    }),
    {
        changeContactFormValues
    }
)(ContactForm);
