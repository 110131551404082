import React, {RefObject} from 'react';
import Translation from '../../../../Translation';
import {WithTranslation, withTranslation} from "react-i18next";

export enum formControlType {
    inputText = 'text',
    inputEmail = 'email',
    textarea = 'textarea',
    checkbox = 'checkbox',
}

interface IFormControlProps extends WithTranslation {
    type: formControlType;
    label: string;
    onChange: (e: any) => void;
    name?: string;
    disabled?: boolean;
    isLabelRaw?: boolean;
}

interface IFormControlState {
    value: string | null;
    isFocused: boolean;
    isPristine: boolean;
    isTouched: boolean;
    error: string | null;
}

class FormControl extends React.Component<IFormControlProps, IFormControlState> {
    private checkboxLabelRef: React.RefObject<HTMLInputElement>;
    private textareaRef: RefObject<any>;

    state = {
        value: null,
        isFocused: false,
        isPristine: false,
        isTouched: false,
        error: null,
    };

    constructor(props: IFormControlProps) {
        super(props);

        this.textareaRef = React.createRef();
        this.checkboxLabelRef = React.createRef();
    }

    render() {
        let labelUp = this.state.value || this.state.isFocused ? ' up' : '';

        return (
            <div className={`form-control${labelUp}`} >
                {this.props.type !== formControlType.checkbox ?
                    (<label><Translation text={`form.${this.props.label}`} /></label>) : null
                }
                { this.renderFormControl() }
                { this.renderError() }
            </div>
        )
    }

    private renderFormControl() {
        const {t} = this.props;

        switch(this.props.type) {
            case formControlType.inputText:
                return (
                    <input type="text"
                           onChange={(e) => this.updateState(e.target)}
                           onFocus={() => this.setState({isFocused: true})}
                           onBlur={(e: any) => this.onBlur(e.target)}/>
                )
            case formControlType.inputEmail:
                return (
                    <input type="email"
                           onChange={(e) => this.updateState(e.target)}
                           onFocus={() => this.setState({isFocused: true})}
                           onBlur={(e: any) => this.onBlur(e.target)}/>
                )
            case formControlType.textarea:
                return (
                    <textarea ref={this.textareaRef}
                              onChange={(e: any) => this.updateState(e.target)}
                              onFocus={() => this.setState({isFocused: true})}
                              onBlur={(e: any) => this.onBlur(e.target)}/>
                )
            case formControlType.checkbox:
                return (
                    <div className="checkbox-container">
                        <div className="checkbox-wrapper">
                            <input className='checkbox-input'
                                   type="checkbox"
                                   onChange={(e: any) => this.updateState(e.target)}
                                   // checked={!!this.props.checked}
                                   id={this.props.name}
                                   name={this.props.name}
                                   disabled={this.props.disabled}/>

                            {this.props.isLabelRaw ? (
                                <span className="checkbox-label"
                                      ref={this.checkboxLabelRef}
                                      dangerouslySetInnerHTML={{__html: t(`form.${this.props.label}`)}}/>
                            ) : (
                                <span className="checkbox-label">
                                <Translation text={`form.${this.props.label}`}/>
                            </span>
                            )
                            }


                            <span className="checkbox-checkmark" />
                        </div>
                    </div>
                )
        }
    }

    private renderError() {
        return (
            <div className="error">
                { this.state.error }
            </div>
        );
    }

    private updateState(target: any) {
        let value = target.value;
        if (this.props.type === formControlType.checkbox) value = target.checked;
        if (value === '') value = null;
        if (!this.state.isPristine) this.setState({isPristine: true})

        const isValid: boolean = this.validate(value);
        this.setState({value: value})

        this.props.onChange(isValid ? value : null)
        if(this.props.type === formControlType.textarea) this.resizeTextarea(target);
    }

    private resizeTextarea(target: HTMLTextAreaElement) {
        this.textareaRef.current.style.height = "2.4rem";
        this.textareaRef.current.style.height = `${(target.scrollHeight - 20)/10}rem`;
    }

    private onBlur = (target: any) => {
        this.setState({isFocused: false});
        this.validate(target.value)
    }

    private validate = (value: string) => {
        let message = null;
        if (value === '') {
            message = 'This field is required.';
        }

        if (this.props.type === formControlType.inputEmail ) {
            let re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if ( !re.test(String(value).toLowerCase())) message = 'Email is incorrect.';
        }

        this.setState({error: message});

        return !message;
    }
}

export default withTranslation()(FormControl);
