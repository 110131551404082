import React from 'react';

interface IBtnNavigationProps {
    slideNumber: number;
    activeSlide: number;
    onSlideChange: (slideNo: number) => void;
}

interface IBtnNavigationState {
}

class BtnNavigation extends React.Component<IBtnNavigationProps, IBtnNavigationState> {

    render() {
        return (
            <div className="btn-navigation">
                <div className={`btn-container btn-prev ${ this.props.activeSlide > 0 ? 'show' : ''}`}>
                    <div className="btn"
                         onClick={() => this.props.onSlideChange(this.props.activeSlide - 1)}/>
                </div>

                <div className="btn-label">SCROLL</div>

                <div className={`btn-container btn-next ${ this.props.activeSlide < (this.props.slideNumber - 1) ? 'show' : ''}`}>
                    <div className="btn"
                         onClick={() => this.props.onSlideChange(this.props.activeSlide + 1)}/>
                </div>
            </div>
        );
    }
}

export default BtnNavigation;
