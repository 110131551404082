import React from 'react';
import { useSpring, animated } from 'react-spring';
import {isMobile} from 'react-device-detect';

interface IBackgroundShapeProps {
    activeSlide: number
}

const BackgroundShape = (props: IBackgroundShapeProps) => {
    const [{ x }, set] = useSpring(() => ({x: props.activeSlide,}));

    set({ x: props.activeSlide });

    return (

            <div className="view-background">

                <svg className="blob" width="100vw" height="100vh" viewBox={isMobile ? "-30 0 10 150" : "50 0 200 200"}>
                    <animated.path
                        d={x.to({
                            range: [0, 1, 2 ,3 ,4 ,5, 6],
                                    output: [
                                        "m 13.6 -22 c 4.6 1.1 9.4 3.5 13 7.4 c 3.5 3.9 5.8 9.2 4.5 13.9 c -1.3 4.6 -6.2 8.4 -10.8 10.4 c -4.7 1.9 -9.1 1.9 -12.5 2.2 c -3.3 0.3 -5.6 0.8 -10.7 5.8 c -5.1 5 -13.2 14.6 -17.2 14.7 c -4.1 0.1 -4.2 -9.1 -4.3 -16.1 c -0.1 -6.9 -0.4 -11.6 -0.9 -16.6 c -0.6 -5.1 -1.6 -10.4 1.5 -12.3 c 3.1 -1.9 10.2 -0.2 14.8 -1.4 c 4.7 -1.1 6.8 -5 10.2 -7 c 3.3 -2 7.8 -2.2 12.4 -1 z",
                                        "m 12.8 -18.5 c 4.4 -1.1 9.1 1 9.9 4.7 c 0.8 3.6 -2.2 8.7 -0.8 14.6 c 1.4 5.9 7.2 12.6 7.6 18.5 c 0.5 5.9 -4.3 10.9 -10.1 10 c -5.9 -0.8 -12.6 -7.5 -17.4 -10.9 c -4.8 -3.4 -7.6 -3.6 -14.1 -2.4 c -6.5 1.2 -16.7 3.9 -19.1 1.4 c -2.4 -2.4 2.9 -9.9 6 -15.6 c 3.1 -5.7 4 -9.6 5.6 -13.5 c 1.5 -4 3.8 -7.9 7.1 -7.4 c 3.4 0.6 8 5.7 12.3 6 c 4.4 0.3 8.6 -4.2 13 -5.4 z",
                                        "m 17.7 -25.6 c 6.9 -1.1 15.2 0.3 18.7 5 c 3.5 4.7 2 12.7 1.1 20.1 c -1 7.4 -1.4 14.2 -4.9 18.8 c -3.6 4.5 -10.2 6.7 -16.2 7.4 c -5.9 0.7 -11.2 -0.2 -16.9 0.8 c -5.8 0.9 -12.1 3.6 -17 2.3 c -4.8 -1.3 -8.2 -6.7 -8.5 -12.1 c -0.3 -5.5 2.4 -11.1 0.3 -17.9 c -2.1 -6.9 -9.2 -14.9 -9.5 -21.5 c -0.3 -6.5 6.2 -11.5 13.1 -10.4 c 7 1.1 14.6 8.3 21 10.2 c 6.5 1.8 11.9 -1.7 18.8 -2.7 z",
                                        "m 11.4 -20.4 c 4.8 3.5 11.2 3.6 16.8 6.4 c 5.7 2.8 10.5 8.4 8.2 12.7 c -2.3 4.2 -11.7 7.2 -16.7 11.1 c -5 3.9 -5.6 8.8 -8.1 11 c -2.6 2.2 -7.1 1.6 -10.7 0.1 c -3.7 -1.5 -6.4 -4 -13.1 -3.8 c -6.6 0.1 -17.1 3 -18.1 0.6 c -1 -2.4 7.5 -10 7.5 -17.7 c -0.1 -7.7 -8.7 -15.5 -10 -21.9 c -1.4 -6.4 4.6 -11.5 11.3 -13.9 c 6.7 -2.4 14.1 -2.2 19.4 1.3 c 5.4 3.6 8.6 10.6 13.5 14.1 z",
                                        "m 16.1 -33 c 0.8 5.6 -5.2 17.9 -3.7 24.5 c 1.4 6.6 10.3 7.6 11.9 9.4 c 1.5 1.8 -4.3 4.6 -5.7 11.3 c -1.3 6.8 1.8 17.6 -0.6 17.7 c -2.4 0.1 -10.2 -10.4 -15.9 -14.1 c -5.7 -3.6 -9.4 -0.2 -16.4 2.4 c -7.1 2.5 -17.6 4.4 -23.9 1.2 c -6.3 -3.1 -8.6 -11.3 -5.6 -17.7 c 2.9 -6.5 11 -11.2 17.9 -13.4 c 7 -2.1 12.7 -1.6 16.9 -5.2 c 4.1 -3.7 6.5 -11.5 11.6 -16 c 5 -4.5 12.7 -5.6 13.5 -0.1 z",
                                        "m 13.7 -22.8 c 1.6 -0.1 -1.3 8.6 2.4 13.9 c 3.6 5.3 13.9 7.1 14.6 9.3 c 0.8 2.3 -7.9 5 -12.1 9.4 c -4.2 4.4 -3.7 10.6 -6 10.2 c -2.4 -0.5 -7.5 -7.5 -11.8 -8.8 c -4.4 -1.3 -8 3.1 -12.3 4.6 c -4.3 1.6 -9.3 0.4 -8.8 -2.8 c 0.6 -3.1 6.6 -8 9.8 -11.2 c 3.1 -3.1 3.4 -4.4 0.9 -10.9 c -2.4 -6.5 -7.6 -18.1 -6.5 -18.3 c 1.1 -0.1 8.6 11.3 15.4 12.5 c 6.8 1.2 12.9 -7.8 14.4 -7.9 z",
                                        "m 11.4 -20.4 c 4.8 3.5 11.2 3.6 16.8 6.4 c 5.7 2.8 10.5 8.4 8.2 12.7 c -2.3 4.2 -11.7 7.2 -16.7 11.1 c -5 3.9 -5.6 8.8 -8.1 11 c -2.6 2.2 -7.1 1.6 -10.7 0.1 c -3.7 -1.5 -6.4 -4 -13.1 -3.8 c -6.6 0.1 -17.1 3 -18.1 0.6 c -1 -2.4 7.5 -10 7.5 -17.7 c -0.1 -7.7 -8.7 -15.5 -10 -21.9 c -1.4 -6.4 4.6 -11.5 11.3 -13.9 c 6.7 -2.4 14.1 -2.2 19.4 1.3 c 5.4 3.6 8.6 10.6 13.5 14.1 z",
                                    ],
                        })}
                        style={{
                            fill: x.to({
                                range: [0, 1, 2, 3 ,4 ,5, 6],
                                output: ["#FFFFFF","#F7A5F1", "#888888", "#829d15", "#fdeae7", "#d3eacf", "#eae7fd"],
                            }),
                        }}
                    />
                </svg>
                {/*<svg className="blob" width={isMobile ? "100vw" : "1500px"} height={isMobile ? "100vh" : "1500px"} viewBox="0 0 256 464">*/}
                {/*    <animated.path*/}
                {/*        d={x.to({*/}
                {/*            range: [0, 1, 2 ,3 ,4 ,5, 6],*/}
                {/*            output: [*/}
                {/*                "M 85.313 32.242 C 57.24 60.86 9.0045 -8.2395 4 32 C -2.4295 84.3606 36.4548 83.1433 27.8817 104.6699 C 19.3086 126.1965 9.0425 138.1384 17.6341 158.9821 C 26.2257 179.8258 53.2786 190.0533 88.3425 182.5516 C 123.4064 175.0498 124.294 101.545 302.593 144.163 C 469.586 91.978 211.269 163.298 195.614 38.053 C 152 55 138.709 -2.251 112.985 11.78 Z",*/}
                {/*                "M 135.313 162.242 C 107.24 190.86 59.0045 121.7605 54 162 C 47.5705 214.3606 86.4548 213.1433 77.8817 234.6699 C 69.3086 256.1965 59.0425 268.1384 67.6341 288.9821 C 76.2257 309.8258 103.2786 320.0533 138.3425 312.5516 C 173.4064 305.0498 215.407 296.317 208.0261 279.7593 C 187.6242 201.204 366 180 278.352 180.95 C 202 185 188.709 127.749 162.985 141.78 Z",*/}
                {/*                "M 157.81292,131.16918 C 128.33979,127.45582 59.004493,121.76045 53.287478,168.06051 47.570462,214.36057 86.454799,213.14326 77.881699,234.66986 69.308599,256.19646 59.042495,268.13837 67.634107,288.98209 76.225718,309.82581 103.27857,320.05328 138.34249,312.55156 173.40641,305.04984 204.93111,298.87002 208.02612,279.75926 211.12113,260.6485 189.48716,257.88808 188.5557,229.54606 187.62424,201.20404 212.01456,174.45091 200.8528,155.7634 189.69104,137.07589 187.28605,134.88254 157.81292,131.16918 Z",*/}
                {/*                "M 157.81292,131.16918 C 128.33979,127.45582 48.756902,138.1566 53.287478,168.06051 57.818054,197.96442 75.182448,197.77187 73.782662,224.42227 72.382877,251.07266 70.314846,257.89078 72.757903,278.7345 75.20096,299.57822 88.114636,303.32873 113.94876,307.60312 139.78288,311.87751 159.84171,314.24141 176.25858,295.13065 192.67546,276.01989 203.83379,256.86332 190.60522,228.5213 177.37665,200.17928 205.866,189.8223 211.10039,171.13479 216.33478,152.44728 187.28605,134.88254 157.81292,131.16918 Z",*/}
                {/*                "M 157.81292,131.16918 C 128.33979,127.45582 86.672992,124.83473 71.733144,166.01099 56.793295,207.18725 69.033893,203.92043 80.955976,230.57083 92.87806,257.22123 55.968217,259.9403 59.436033,279.75926 62.90385,299.57822 94.985717,299.83924 132.0922,306.16316 169.19868,312.48708 186.48544,320.38997 198.80328,288.98209 211.12113,257.57422 199.73475,245.59097 195.72902,217.24895 191.72328,188.90693 209.96504,178.54995 215.19943,159.86244 220.43382,141.17493 187.28605,134.88254 157.81292,131.16918 Z",*/}
                {/*                "M 85.313 32.242 C 57.24 60.86 9.0045 -8.2395 4 32 C -2.4295 84.3606 36.4548 83.1433 27.8817 104.6699 C 19.3086 126.1965 9.0425 138.1384 17.6341 158.9821 C 26.2257 179.8258 53.2786 190.0533 88.3425 182.5516 C 123.4064 175.0498 165.407 166.317 158.0261 149.7593 C 137.6242 71.204 316 50 228.352 50.95 C 152 55 138.709 -2.251 112.985 11.78 Z",*/}
                {/*            ],*/}
                {/*        })}*/}
                {/*        style={{*/}
                {/*            fill: x.to({*/}
                {/*                range: [0, 1, 2, 3 ,4 ,5],*/}
                {/*                output: ["#F7A5F1", "#888888", "#829d15", "#fdeae7", "#d3eacf", "#eae7fd"],*/}
                {/*            }),*/}
                {/*        }}*/}
                {/*    />*/}
                {/*</svg>*/}
            </div>
    );
};

export default BackgroundShape;
